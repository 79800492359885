<template>
  <div class="my-question">
    <div class="main">
      <div class="title">我的提问</div>
      <div class="question-list">
        <div
          class="question-item"
          v-for="(item, index) in questionList"
          :key="index"
          v-show="item.state === 'COMPLETED'"
          @click="toQuestionDetail(item)"
        >
          <div class="item-top">
            <div class="content">{{ item.content }}</div>
            <div class="status" :class="{ replied: item.isReplied }">
              {{ item.isReplied ? "已回复" : "等待回复" }}
            </div>
          </div>

          <div class="time">{{ moment(item.questionTime).calendar() }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "my-question",
  data() {
    return {
      moment,
      questionList: [1, 2, 3],
    };
  },
  created() {
    document.title = "我的提问";
    moment.locale("zh-cn");
    this.getMyQuestion();
  },
  methods: {
    getMyQuestion() {
      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios
        .post("/api/user/advisory/myList", {
          keyword: "",
          limit: 100,
          offset: 0,
        })
        .then((res) => {
          console.log(res);
          loading.close();
          this.questionList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
          loading.close();
        });
    },
    toQuestionDetail(item) {
      this.$router.push("/xwQuestionDetail?id=" + item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f2f2f2;
  .main {
    background-color: #ffffff;
    width: 768px;
    .title {
      text-align: center;
      line-height: 60px;
      font-size: 18px;
    }
    .question-list {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      .question-item {
        padding: 10px 0;
        border-bottom: 0.5px solid #f2f2f2;
        .item-top {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .content {
            color: #333;
            font-size: 13px;
            padding-bottom: 10px;
            white-space: normal !important;
            font-family: PingFang SC, Helvetica Neue, Helvetica,
              Hiragino Sans GB, Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial,
              sans-serif;
            &:hover {
              color: #00c792;
              cursor: pointer;
            }
          }
          .status {
            flex: 0 0 100px;
            text-align: right;
            color: #ffb54c;
          }
          .replied {
            color: #00c792;
          }
        }

        .time {
          //   font-size: 12px;
          color: #999;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .my-question {
    .main {
      width: 100%;
    }
  }
}
</style>